<template>
	<div class="row mt-5">
		<div class="col-12">
			<div class="embed-responsive embed-responsive-16by9 img-responsive"
				 v-if="jsonConfig.youtube.image.active">
				<img :src="jsonConfig.youtube.image.src" id="play-video"
					 class="img-responsive">
			</div>
			<div class="embed-responsive embed-responsive-16by9 img-responsive"
				 v-if="jsonConfig.youtube.image.active">
				<i class="fas fa-play-circle fa-4x" @click="playVideo"></i>
			</div>
			<div class="embed-responsive embed-responsive-16by9">
				<youtube :video-id="jsonConfig.youtube.video.id" ref="youtube"></youtube>
			</div>
		</div>
	</div>
</template>

<script>
import Vue        from 'vue';
import VueYoutube from 'vue-youtube';

Vue.use(VueYoutube);

export default {
	name     : 'Video',
	props    : {
		jsonConfig : {
			type     : Object,
			required : true
		}
	},
	computed : {
		player() {
			return this.$refs.youtube.player;
		}
	},
	methods  : {
		playVideo() {
			this.player.playVideo();
			let elements = document.getElementsByClassName('img-responsive');

			for (let e of elements) {
				e.setAttribute('style', 'display:none;');
			}
		},
	}
};
</script>
