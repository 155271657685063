<template>
	<hooper :settings="hooperSettings" :infinite-scroll="true" :auto-play="true" :play-speed="2000">
		<slide v-for="(slide, indx) in images" :key="indx" :index="indx">
			<img :src="setImgFallback(slide)" alt="" class="img-fluid">
		</slide>
		<hooper-navigation
			v-if="jsonConfig.slideshow.show_navigation !== undefined && jsonConfig.slideshow.show_navigation"
			slot="hooper-addons"></hooper-navigation>
		<hooper-pagination v-if="jsonConfig.slideshow.show_pagination" slot="hooper-addons"
						   style="margin-bottom: 1.5rem;"></hooper-pagination>
	</hooper>
</template>

<script>
import setImgFallbackMixin                                                             from '@/common/set-img-fallback-mixin';
import {Hooper, Navigation as HooperNavigation, Pagination as HooperPagination, Slide} from 'hooper';

import 'hooper/dist/hooper.css';

export default {
	name       : 'Slideshow',
	components : {
		Hooper,
		HooperNavigation,
		HooperPagination,
		Slide
	},
	mixins     : [setImgFallbackMixin],
	props      : {
		jsonConfig : {
			type     : Object,
			required : true
		},
		images     : {
			type     : Array,
			required : true
		}
	},
	data() {
		return {
			hooperSettings : {
				centerMode   : true,
				wheelControl : false,
			},
		};
	},
	created() {
		this.hooperSettings.breakpoints = {
			300  : {
				centerMode  : true,
				itemsToShow : this.jsonConfig.slideshow !== undefined && this.jsonConfig.slideshow.mobile_items !== undefined ? this.jsonConfig.slideshow.mobile_items : 1,
			},
			768  : {
				centerMode  : true,
				itemsToShow : this.jsonConfig.slideshow !== undefined && this.jsonConfig.slideshow.tablet_items !== undefined ? this.jsonConfig.slideshow.tablet_items : 1,
			},
			1280 : {
				centerMode  : true,
				itemsToShow : this.jsonConfig.slideshow !== undefined && this.jsonConfig.slideshow.desktop_items !== undefined ? this.jsonConfig.slideshow.desktop_items : 2,
			},
		};
	},
	methods : {}
};
</script>
