<template>
  <div class="row">
    <div class="col-12">
      <div class="row" v-if="!success">
        <div class="col-12">
          <h2
            v-html="formData.form_title"
            class="form-title"
            v-if="zone !== 'modal'"
          ></h2>
          <h2
            v-html="formData.form_subtitle"
            class="form-sub-title text-center"
            v-if="zone !== 'modal'"
          ></h2>
          <form
            action=""
            method="post"
            :class="{
              compact: formData.compact_form_desktop === 1,
              'compact-mobile': formData.compact_form_mobile === 1,
            }"
            @submit.prevent="onSubmit"
          >
            <template>
              <div
                class="alert alert-danger"
                role="alert"
                v-if="errors && errors.length"
              >
                <ul>
                  <li v-for="error of errors">
                    {{ error }}
                  </li>
                </ul>
              </div>
            </template>

            <div
              class="form-group mb-2 mt-3"
              v-if="
                formData.field_version === 1 &&
                !isCatalog &&
                Object.keys(config.version_values).length > 1
              "
            >
              <select
                v-model="form.fields.version"
                id="version"
                @change="onChangeSelect"
                :placeholder="formData.version_placeholder"
                class="form-control"
                required
              >
                <option value="" disabled selected>
                  {{ formData.version_placeholder }} *
                </option>
                <option v-for="(k, v) of config.version_values" :value="v">
                  {{ k }}
                </option>
              </select>
            </div>

            <!-- // Products field-->
            <div
              class="form-group mb-2 mt-3"
              v-if="
                formData.field_products === 1 &&
                Object.keys(config.products_values).length > 1
              "
            >
              <multiselect
                v-model="products"
                id="products"
                :multiple="
                  formData.field_products_multiselect === undefined ||
                  formData.field_products_multiselect === 1
                "
                :close-on-select="true"
                :searchable="false"
                :options="config.products_values"
                :placeholder="formData.products_placeholder"
                track-by="id"
                label="value"
                :custom-label="customLabel"
                :show-labels="false"
                required
              >
                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <span class="option__title">{{ props.option.value }}</span>
                  </div>
                </template>
              </multiselect>
            </div>

            <div
              v-if="
                formData.field_visit_date === 1 &&
                formData.placeholder_see_all_conditional === null
              "
              class="form-group mb-2 mt-3"
            >
              <select
                id="visit_date"
                v-model="form.fields.visit_date"
                :placeholder="formData.visit_date_placeholder"
                class="form-control"
                :required="formData.field_visit_date_required === 1"
              >
                <option value="" disabled selected>
                  {{ formData.visit_date_placeholder }}
                  {{ formData.field_visit_date_required === 1 ? "*" : "" }}
                </option>
                <option
                  v-for="(k, v) of config.visit_date_values"
                  :value="v"
                  :disabled="setDisabledOption(k)"
                >
                  {{ k }}
                </option>
              </select>
            </div>

            <div v-if="!config.is_type_apv">
              <div
                class="form-group mb-2 mt-3"
                v-if="
                  formData.field_location === 1 &&
                  !isCatalog &&
                  config.form_locations.length > 1
                "
              >
                <select
                  id="location"
                  v-model="form.fields.location"
                  :placeholder="formData.location_placeholder"
                  class="form-control"
                  required
                >
                  <option value="" disabled selected>
                    {{ formData.location_placeholder }} *
                  </option>
                  <option
                    v-for="(location, index) of config.form_locations"
                    :value="location.address_id"
                  >
                    {{ location.StandName }}
                  </option>
                </select>
              </div>
            </div>

            <div v-if="config.is_type_apv">
              <div class="form-group mb-2 mt-3">
                <select
                  id="location"
                  v-model="form.fields.location"
                  :placeholder="formData.location_placeholder"
                  class="form-control"
                  required
                >
                  <option value="" disabled selected>
                    {{ formData.location_placeholder }} *
                  </option>
                  <option
                    v-for="(location, index) of conditionalLocation"
                    :value="location.address_id"
                  >
                    {{ location.StandName }}
                  </option>
                </select>
              </div>
            </div>

            <div class="form-group mb-2 mt-3">
              <input
                type="text"
                :placeholder="formData.placeholder_name + '*'"
                v-model="form.name"
                required
                pattern="([A-Z a-z áàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ])*"
                class="form-control"
              />
            </div>

            <div class="form-group mb-2 mt-3" v-if="formData.field_email === 1">
              <input
                type="email"
                v-model="form.email"
                :placeholder="formData.placeholder_email + '*'"
                required
                pattern="[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?"
                class="form-control"
              />
            </div>

            <div class="form-group mb-2 mt-3" v-if="formData.field_phone === 1">
              <input
                type="text"
                :placeholder="
                  formData.placeholder_phone +
                  (formData.field_phone_required === 1 ? '*' : '')
                "
                v-model="form.phone"
                :required="formData.field_phone_required === 1"
                pattern="([0-9]{9})"
                class="form-control"
              />
            </div>

            <div
              class="form-group mb-2 mt-3"
              v-if="formData.field_license_plate === 1 && !isCatalog"
            >
              <input
                type="text"
                :placeholder="formData.license_plate_placeholder"
                v-model="form.fields.license_plate"
                :required="formData.license_plate_required === 1"
                pattern="^([A-Za-z]{2}|\d\d)-([A-Za-z]{2}|\d\d)-([A-Za-z]{2}|\d\d)|([A-Za-z]{2}|\d\d)([A-Za-z]{2}|\d\d)([A-Za-z]{2}|\d\d)$"
                maxlength="8"
                class="form-control"
              />
            </div>

            <div
              class="form-group mb-2 mt-3"
              v-if="formData.field_nif === 1 && !isCatalog"
            >
              <input
                type="text"
                :placeholder="formData.nif_placeholder"
                v-model="form.fields.nif"
                :required="formData.nif_required === 1"
                class="form-control"
              />
            </div>

            <div
              class="form-group mb-2 mt-3"
              v-if="formData.field_activity === 1 && !isCatalog"
            >
              <input
                type="text"
                :placeholder="formData.activity_placeholder"
                v-model="form.fields.activity"
                pattern="([A-Z a-z áàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ])*"
                class="form-control"
              />
            </div>
            <!--    Campos Extra	 -->
            <div
              class="mb-4 mt-3"
              v-if="jsonConfig.inputs !== undefined"
              id="fields-extra"
            >
              <div
                class="form-group mb-2 mt-3"
                v-for="fields in jsonConfig.inputs"
              >
                <div v-if="fields.type === 'text'">
                  <input
                    type="text"
                    :placeholder="
                      fields.placeholder + (fields.required ? '*' : '')
                    "
                    :name="fields.name"
                    :required="fields.required === true"
                    class="form-control"
                  />
                </div>
                <div
                  class="form-group mb-2 mt-3"
                  v-if="fields.type === 'select'"
                >
                  <select
                    :name="fields.name"
                    :placeholder="fields.placeholder"
                    class="form-control"
                    :required="fields.required === true"
                  >
                    <option value="" disabled selected>
                      {{ fields.placeholder }}
                      <span v-if="fields.required === true">*</span>
                    </option>
                    <option
                      v-for="(value, index) of fields.value"
                      :value="value"
                    >
                      {{ value }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <!--    Campos Extra Fim	 -->

            <!--						new fields -->
            <div
              class="form-group mb-2 mt-3"
              v-if="formData.field_make_model === 1 && !isCatalog"
            >
              <input
                type="text"
                :placeholder="formData.make_model_placeholder"
                v-model="form.fields.make_model"
                class="form-control"
              />
            </div>

            <div
              class="form-group mb-2 mt-3"
              v-if="formData.field_fuel_type === 1 && !isCatalog"
            >
              <select
                :placeholder="formData.fuel_type_placeholder"
                v-model="form.fields.fuel_type"
                class="form-control"
              >
                <option value="" disabled selected>
                  {{ formData.fuel_type_placeholder }}
                </option>
                <option v-for="(k, v) of config.fuel_type_values" :value="v">
                  {{ k }}
                </option>
              </select>
            </div>

            <div
              class="form-group mb-2 mt-3"
              v-if="formData.field_age === 1 && !isCatalog"
            >
              <select
                :placeholder="formData.age_placeholder"
                v-model="form.fields.age"
                class="form-control"
              >
                <option value="" disabled selected>
                  {{ formData.age_placeholder }}
                </option>
                <option v-for="(k, v) of config.age_values" :value="v">
                  {{ k }}
                </option>
              </select>
            </div>

            <div
              class="form-group mb-2 mt-3"
              v-if="formData.field_kms === 1 && !isCatalog"
            >
              <select
                :placeholder="formData.kms_placeholder"
                v-model="form.fields.kms"
                class="form-control"
              >
                <option value="" disabled selected>
                  {{ formData.kms_placeholder }}
                </option>
                <option v-for="(k, v) of config.kms_values" :value="v">
                  {{ k }}
                </option>
              </select>
            </div>
            <!--						new fields end -->

            <p
              class="mt-4 form-text-color"
              v-if="formData.field_partexchange === 1"
            >
              {{ formData.placeholder_partexchange }} *
            </p>

            <div
              class="custom-control custom-radio custom-control-inline"
              v-if="formData.field_partexchange === 1"
            >
              <input
                type="radio"
                id="part_exchange1"
                name="part_exchange"
                required
                v-model="form.fields.part_exchange"
                class="custom-control-input"
                value="Sim"
              />
              <label
                class="custom-control-label"
                for="part_exchange1"
                style="font-size: 1rem"
                >{{ formData.placeholder_yes }}</label
              >
            </div>
            <div
              class="custom-control custom-radio custom-control-inline"
              v-if="formData.field_partexchange === 1"
            >
              <input
                type="radio"
                id="part_exchange2"
                name="part_exchange"
                required
                v-model="form.fields.part_exchange"
                class="custom-control-input"
                value="Não"
              />
              <label
                class="custom-control-label"
                for="part_exchange2"
                style="font-size: 1rem"
                >{{ formData.placeholder_no }}</label
              >
            </div>

            <div class="form-group mb-2 mt-3">
              <textarea
                v-model="form.fields.obs"
                maxlength="500"
                :placeholder="formData.placeholder_obs"
                rows="3"
                :disabled="obsParam"
                v-if="
                  form.fields.part_exchange === 'Sim' ||
                  formData.field_part_no_switch === 1 ||
                  obsParam
                "
                :required="
                  (formData.field_part_no_switch_required === 1 &&
                    form.fields.part_exchange === true) ||
                  formData.field_part_no_switch_required === 1
                "
                class="form-control"
              ></textarea>
            </div>

            <div
              class="text-center my-1 tooltip-contact"
              v-if="jsonConfig.obs_tooltip !== undefined"
            >
              <i class="fas fa-info-circle"></i>
              <a v-b-tooltip.hover :title="jsonConfig.obs_tooltip.title"
                ><span v-html="jsonConfig.obs_tooltip.text"></span
              ></a>
            </div>

            <div
              v-if="
                formData.field_visit_date === 1 &&
                formData.placeholder_see_all_conditional !== null
              "
              class="form-group mb-2 mt-3"
            >
              <label
                class="visit_date_optional"
                v-if="
                  formData.field_visit_date_conditional_text !== undefined &&
                  formData.field_visit_date_conditional_text !== null
                "
                v-html="formData.field_visit_date_conditional_text"
              ></label>
              <select
                id="visit_date_2"
                v-model="form.fields.visit_date"
                :placeholder="formData.visit_date_placeholder"
                class="form-control"
                :required="formData.field_visit_date_required === 1"
              >
                <option
                  value=""
                  :disabled="formData.field_visit_date_required === 1"
                  selected
                >
                  {{ formData.visit_date_placeholder }}
                  {{ formData.field_visit_date_required === 1 ? "*" : "" }}
                </option>
                <option
                  v-for="(k, v) of config.visit_date_values"
                  :value="v"
                  :disabled="setDisabledOption(k)"
                >
                  {{ k }}
                </option>
              </select>
            </div>

            <div
              class="custom-control custom-checkbox mb-4 mt-4"
              v-if="formData.field_whatsapp === 1"
            >
              <input
                class="custom-control-input"
                type="checkbox"
                v-model="form.fields.whatsapp"
                value="true"
                id="checkbox3"
              />
              <label
                class="custom-control-label"
                for="checkbox3"
                style="font-size: 1rem"
              >
                <i class="fab fa-whatsapp"></i>
                {{ formData.placeholder_whatsapp }}
              </label>
            </div>

            <!-- marketing -->
            <div class="pt-1" v-if="formData.field_marketing === 1">
              <label
                style="font-size: 0.8rem; font-weight: 700"
                class="marketingv2"
                v-html="marketingText"
              ></label>
            </div>

            <div
              class="custom-control custom-radio custom-control-inline"
              v-if="formData.field_marketing === 1"
            >
              <input
                type="radio"
                id="marketingv21"
                name="marketing"
                required
                v-model="form.marketing"
                class="custom-control-input"
                :value="true"
              />
              <label
                class="custom-control-label"
                for="marketingv21"
                style="font-size: 1rem; font-weight: 700"
              >
                {{ formData.placeholder_yes }}
              </label>
            </div>

            <div
              class="custom-control custom-radio custom-control-inline"
              v-if="formData.field_marketing === 1"
            >
              <input
                type="radio"
                id="marketingv22"
                name="marketing"
                required
                v-model="form.marketing"
                class="custom-control-input"
                :value="false"
              />
              <label
                class="custom-control-label"
                for="marketingv22"
                style="font-size: 1rem; font-weight: 700"
              >
                {{ formData.placeholder_no }}
              </label>
            </div>

            <!-- marketing extra -->
            <div
              class="pt-1"
              v-if="
                formData.field_marketing_extra !== undefined &&
                formData.field_marketing_extra === 1
              "
            >
              <label
                style="font-size: 0.8rem; font-weight: 700"
                class="marketingv2"
                v-html="formData.marketing_text_extra"
              ></label>
            </div>

            <div
              class="custom-control custom-radio custom-control-inline"
              v-if="
                formData.field_marketing_extra !== undefined &&
                formData.field_marketing_extra === 1
              "
            >
              <input
                type="radio"
                id="marketing_extra_1"
                name="marketing_extra"
                required
                v-model="form.fields.marketing_extra"
                class="custom-control-input"
                :value="true"
              />
              <label
                class="custom-control-label"
                for="marketing_extra_1"
                style="font-size: 1rem; font-weight: 700"
              >
                {{ formData.placeholder_yes }}
              </label>
            </div>

            <div
              class="custom-control custom-radio custom-control-inline"
              v-if="
                formData.field_marketing_extra !== undefined &&
                formData.field_marketing_extra === 1
              "
            >
              <input
                type="radio"
                id="marketing_extra_2"
                name="marketing_extra"
                required
                v-model="form.fields.marketing_extra"
                class="custom-control-input"
                :value="false"
              />
              <label
                class="custom-control-label"
                for="marketing_extra_2"
                style="font-size: 1rem; font-weight: 700"
              >
                {{ formData.placeholder_no }}
              </label>
            </div>

            <!-- rgpd -->
            <div
              class="custom-control custom-checkbox mb-4 mt-4"
              v-if="enableRgpdField()"
            >
              <input
                class="custom-control-input"
                type="checkbox"
                v-model="form.rgpd"
                required
                value="true"
                id="checkbox2"
              />
              <label class="custom-control-label" for="checkbox2">
                <span v-html="rgpdText" style="font-weight: 700"></span> *
              </label>
            </div>

            <p style="font-size: 0.8rem" class="form-text-color">
              * {{ formData.placeholder_required_fields }}
            </p>

            <button
              class="btn btn-primary mt-2 btn-block"
              style="margin-bottom: 30px"
              :disabled="submitButtonDisabled"
            >
              <span v-if="loadSpinner"> <i class="fas fa-spinner"></i></span>
              <span v-if="!loadSpinner" v-html="formData.form_button"></span>
            </button>
          </form>
        </div>
      </div>

      <div class="row" v-if="success">
        <div class="col-12">
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            style="margin: 120px auto -60px auto; display: block"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="36px"
            height="35px"
            viewBox="0 0 36 35"
            enable-background="new 0 0 36 35"
            xml:space="preserve"
          >
            <g>
              <g>
                <path
                  fill="#FECE00"
                  d="M32.168,14.269c-1.015,0.001-1.841,0.827-1.841,1.842v1.386c-0.002,3.492-1.364,6.775-3.834,9.244
			c-2.468,2.467-5.749,3.828-9.243,3.831c-3.487,0.001-6.768-1.36-9.239-3.832c-2.471-2.471-3.83-5.752-3.829-9.238
			c0.002-3.492,1.364-6.775,3.834-9.244c2.469-2.468,5.749-3.828,9.243-3.831c1.845,0,3.633,0.379,5.316,1.128
			c0.907,0.404,2.031-0.034,2.431-0.934c0.413-0.927-0.005-2.018-0.933-2.43c-2.157-0.96-4.448-1.446-6.819-1.445
			c-4.471,0.003-8.676,1.747-11.841,4.91C2.247,8.82,0.503,13.028,0.5,17.503c-0.003,4.476,1.738,8.683,4.9,11.847
			c3.163,3.164,7.369,4.906,11.845,4.905l0.01,0c4.471-0.003,8.676-1.747,11.841-4.909c3.166-3.165,4.911-7.373,4.914-11.849v-1.387
			C34.009,15.094,33.183,14.269,32.168,14.269z"
                />
                <path
                  fill="#FECE00"
                  d="M34.961,2.771c-0.696-0.696-1.909-0.695-2.604,0.002L17.254,17.887l-3.172-3.169
			c-0.695-0.696-1.909-0.695-2.604,0.002c-0.718,0.718-0.718,1.886,0,2.603l4.474,4.471c0.348,0.347,0.811,0.538,1.302,0.538
			c0.491,0,0.954-0.192,1.302-0.54L34.961,5.375C35.68,4.657,35.68,3.488,34.961,2.771z"
                />
              </g>
            </g>
          </svg>
          <h2 class="text-center mt-5 thank-you">
            {{ formData.placeholder_thank_you }}
          </h2>
        </div>
        <div class="col-12">
          <p class="mt-5 text-center success-message">
            {{ formData.placeholder_success }}
          </p>
        </div>

        <div class="col-12 mt-5" style="margin-bottom: 150px">
          <p
            style="color: white"
            class="text-center success-message2"
            v-if="
              (formData.placeholder_see_all_conditional_link !== null &&
                formData.placeholder_see_all_conditional !== null &&
                form.fields.visit_date === '') ||
              (formData.placeholder_see_all_conditional_link === null &&
                formData.placeholder_see_all_conditional === null &&
                form.fields.visit_date === '') ||
              (formData.placeholder_see_all_conditional_link === null &&
                formData.placeholder_see_all_conditional === null &&
                form.fields.visit_date !== '')
            "
          >
            {{ formData.placeholder_see_all }}<br />
            <a v-bind:href="setup.company_url" rel="noopener" target="_blank">
              {{ setup.company_url }}
            </a>
          </p>

          <p
            style="color: white"
            class="text-center success-message2"
            v-if="
              formData.placeholder_see_all_conditional_link !== null &&
              formData.placeholder_see_all_conditional !== null &&
              form.fields.visit_date !== ''
            "
          >
            {{ formData.placeholder_see_all_conditional }}
            <br />
            <a
              v-bind:href="formData.placeholder_see_all_conditional_link"
              rel="noopener"
              target="_blank"
            >
              {{ formData.placeholder_see_all_conditional_link }}
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "@/assets/styles/scss/_contact-form.scss";
import blockListMixin from "@/common/block-list-mixin";
import router from "@/router";
import VueGtm from "@gtm-support/vue2-gtm";
import axios from "axios";
import moment from "moment";
import Vue from "vue";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import { mapState } from "vuex";

moment.locale("pt");

export default {
  name: "ContactForm",
  components: {
    Multiselect,
  },
  mixins: [blockListMixin],
  props: {
    config: {
      type: Object,
      required: true,
    },
    formData: {
      type: Object,
      required: true,
    },
    setup: {
      type: Object,
      required: true,
    },
    jsonConfig: {
      type: Object,
      required: true,
    },
    zone: {
      default: "",
      type: String,
      required: false,
    },
  },
  data() {
    return {
      sellerId: this.setup.seller_id,
      form: {
        uuid: this.$store.state.alid,
        name: "",
        email: "",
        phone: "",
        fields: {
          version: "",
          visit_date: "",
          activity: "",
          license_plate: "",
          whatsapp: false,
          day: "",
          period: "",
          stand: "",
          location: "",
          nif: "",
          products: [],
          marketing_extra: Boolean,
          make_model: "",
          fuel_type: "",
          age: "",
          kms: "",
          extrafields_json: [],
        },
        product_type: String,
        marketing: Boolean,
        rgpd: false,
        ad_engine_lps_id: String,
        listing_id: String,
        test_lead: Boolean,
        disable_send_email: Boolean,
        email_to_send: String,
        template_to_send: String,
      },
      gtmTrigger: Boolean,
      formSubmitToServer: Boolean,
      marketingText: String,
      rgpdText: String,
      submitButtonDisabled: false,
      errors: [],
      success: false,
      loadSpinner: false,
      isCatalog: false,
      products: [],
      obsParam: false,
      conditionalLocation: [],
    };
  },
  computed: mapState(["showSuccessForm", "fillForm"]),
  watch: {
    showSuccessForm(newValue, oldValue) {
      this.success = newValue;
    },
    fillForm(newValue, oldValue) {
      this.enableFillForm();
    },
  },
  async mounted() {
    let location = this.$store.state.formVersion.address_id;
    if (location !== undefined) {
      const addressId = location;
      let match = this.config.form_locations.filter(function (el) {
        return el.id === addressId;
      });

      if (match.length > 0) {
        this.form.fields.location = match[0].address_id;
        document.getElementById("location").style.display = "none";
      }
    } else {
      document.getElementById("location").style.display = "block";
      this.form.fields.location = "";
    }
  },
  created() {
    this.isCatalog =
      this.config.template === "catalog_v1" ||
      this.config.template === "catalog_v2";
    this.marketingText = this.setMarketingText();
    this.rgpdText = this.setRgpdText();
    // populate fields
    this.form.ad_engine_lps_id = this.config.id;
    this.form.product_type = this.isCatalog ? "catalog" : "landing-page";
    this.form.listing_id = this.isCatalog ? this.$store.state.listingId : "";
    this.form.fields.location = this.isCatalog
      ? this.$store.state.listingLocationId
      : this.config.form_locations !== null &&
        this.config.form_locations.length === 1
      ? this.config.form_locations[0].address_id
      : "";
    this.products =
      this.config.form.field_products === 1 &&
      this.config.products_values.length < 2
        ? this.config.products_values[0].id
        : [];

    this.$eventBus.$on("change-version", (data) => {
      this.form.fields.version = data;
    });

    this.setObsParam();
  },
  methods: {
    onChangeSelect() {
      if (this.sellerId === 435 && this.config.is_type_apv) {
        this.conditionalLocation = [];
        if (
          this.form.fields.version.includes("Volkswagen") ||
          this.form.fields.version.includes("VKW VC")
        ) {
          this.conditionalLocation = this.config.form_locations.filter(
            function (el) {
              return (
                el.address_id === 128 ||
                el.address_id === 96 ||
                el.address_id === 79 ||
                el.address_id === 71 ||
                el.address_id === 88
              );
            }
          );
        } else if (this.form.fields.version.includes("Audi")) {
          this.conditionalLocation = this.config.form_locations.filter(
            function (el) {
              return (
                el.address_id === 128 ||
                el.address_id === 154 ||
                el.address_id === 96 ||
                el.address_id === 79 ||
                el.address_id === 76 ||
                el.address_id === 71 ||
                el.address_id === 88
              );
            }
          );
        } else if (this.form.fields.version.includes("Skoda")) {
          this.conditionalLocation = this.config.form_locations.filter(
            function (el) {
              return (
                el.address_id === 71 ||
                el.address_id === 96 ||
                el.address_id === 79 ||
                el.address_id === 88
              );
            }
          );
        } else if (this.form.fields.version.includes("Seat")) {
          this.conditionalLocation = this.config.form_locations.filter(
            function (el) {
              return (
                el.address_id === 149 ||
                el.address_id === 71 ||
                el.address_id === 88
              );
            }
          );
        } else if (
          this.form.fields.version.includes("CUPRA") ||
          this.form.fields.version.includes("cupra") ||
          this.form.fields.version.includes("Cupra")
        ) {
          this.conditionalLocation = this.config.form_locations.filter(
            function (el) {
              return (
                el.address_id === 150 ||
                el.address_id === 148 ||
                el.address_id === 88
              );
            }
          );
        }
        this.form.fields.location = "";
      }
    },
    setDisabledOption(date) {
      return (
        moment(new Date(date.toLowerCase())).format("MM-DD") <=
        moment().format("MM-DD")
      );
    },
    enableRgpdField() {
      if (this.setup.form_disable_rgpd_check === 1) {
        return false;
      }

      return this.formData.field_rgpd_default === 1;
    },

    getValues() {
      let values = {};
      if (this.jsonConfig.inputs !== undefined) {
        for (let i = 0; i < this.jsonConfig.inputs.length; i++) {
          let name = this.jsonConfig.inputs[i].name;
          values[name] = document.getElementsByName(name)[0].value;
        }
      }

      return values;
    },

    enableFillForm() {
      if (this.$store.state.fillForm === true) {
        this.form.email = "teste@rotauto.com";
        this.form.name = "Teste";
        this.form.phone = "910000000";
        this.form.rgpd = true;
        this.form.marketing = true;
        this.form.fields.part_exchange = "Sim";
        this.form.fields.activity = "Tester";
        this.form.fields.license_plate = "AA-00-AA";
        this.form.fields.nif = "123456789";
        this.form.fields.obs = "Este é apenas um teste";
        this.form.fields.marketing_extra = true;
        this.form.fields.whatsapp = true;
        this.form.fields.location = 1;
        this.form.fields.version =
          document.getElementById("version") !== null
            ? document
                .getElementById("version")
                .getElementsByTagName("option")[1].value
            : "";
        this.form.fields.visit_date =
          document.getElementById("visit_date") !== null
            ? document
                .getElementById("visit_date")
                .getElementsByTagName("option")[1].value
            : "";
        this.form.fields.products = ["test product"];
      } else {
        this.form.email = "";
        this.form.name = "";
        this.form.phone = "";
        this.form.rgpd = false;
        this.form.marketing = undefined;
        this.form.fields.part_exchange = undefined;
        this.form.fields.activity = "";
        this.form.fields.license_plate = "";
        this.form.fields.nif = "";
        this.form.fields.obs = "";
        this.form.fields.marketing_extra = undefined;
        this.form.fields.whatsapp = undefined;
        this.form.fields.location = "";
        this.form.fields.version =
          document.getElementById("version") !== null
            ? document
                .getElementById("version")
                .getElementsByTagName("option")[0].value
            : "";
        this.form.fields.visit_date =
          document.getElementById("visit_date") !== null
            ? document
                .getElementById("visit_date")
                .getElementsByTagName("option")[0].value
            : "";
        this.form.fields.products = [];
      }
    },

    setRgpdText() {
      if (
        this.setup.privacy_name !== null &&
        this.setup.privacy_email !== null &&
        this.setup.privacy_address !== null
      ) {
        return `Li e aceito os <a href="${this.config.full_url}/termos-condicoes/${this.sellerId}" target="_blank" rel="noopener">Termos e Condições</a>
                        e <a href="${this.config.full_url}/politica-privacidade/${this.sellerId}" target="_blank" rel="noopener">Política de Privacidade</a>.`;
      }
      return this.setup.form_rgpd_acceptance;
    },

    setMarketingText() {
      if (
        this.setup.privacy_name !== null &&
        this.setup.privacy_email !== null &&
        this.setup.privacy_address !== null
      ) {
        return `Autorizo, de forma livre e voluntária, a utilização e tratamento dos seus dados pessoais
                    para a realização de acções de marketing directo, envio de informações sobre produtos e serviços
                    da ${this.setup.privacy_name} e envio de newsletters, através de e-mail e telefone/SMS.`;
      } else if (this.setup.form_force_marketing_texts === 1) {
        return this.setup.form_marketing_acceptance;
      }

      return this.setup.form_marketing_acceptance;
    },

    customLabel({ value }) {
      return `${value}`;
    },

    setGtm() {
      let ids;
      if (this.setup.gtm_id.indexOf(",") > -1) {
        ids = this.setup.gtm_id.split(",");
      } else {
        ids = this.setup.gtm_id;
      }
      Vue.use(VueGtm, {
        id: ids, // Your GTM ID
        enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
        debug: true, // Whether or not display console logs debugs (optional)
        vueRouter: router, // Pass the router instance to automatically sync with router (optional)
        ignoredViews: [], // If router, you can exclude some routes name (case insensitive) (optional)
      });
    },

    setObsParam() {
      if (
        this.$route.query.obs_message !== undefined &&
        this.$route.query.obs_message !== "" &&
        this.$route.query.obs_message !== null
      ) {
        this.obsParam = true;
        this.form.fields.obs = this.$route.query.obs_message;
      }
    },

    onSubmit(e) {
      e.preventDefault();
      const url = `${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_ENDPOINT}`;
      const vm = this;
      vm.loadSpinner = true;

      if (
        this.$cookies.get(`${this.sellerId}-autoleads-cookie`) === null &&
        !this.setup.force_cookie_consent
      ) {
        this.setGtm();
      }

      if (!this.$store.state.gtmTrigger) {
        this.$gtm.trackEvent({
          event: "formButtonClicked", // Event type [default = 'interaction'] (Optional)
          category: "formButtonClicked",
          action: "formButtonClicked",
          label: "formButtonClicked",
          eventID: this.$store.state.alid,
          value: 0,
          noninteraction: false, // Optional
        });
      }
      window.alp.trackEvent({ event: "formButtonClicked" });

      vm.submitButtonDisabled = true;
      vm.form.rgpd =
        this.setup.form_disable_rgpd_check === 1 ? true : vm.form.rgpd;
      vm.form.listing_id = this.isCatalog ? this.$store.state.listingId : "";
      vm.form.test_lead = this.$store.state.testLead;
      vm.form.disable_send_email = this.$store.state.disableSendMail;
      vm.form.email_to_send = this.$store.state.emailToSend;
      vm.form.template_to_send = this.$store.state.templateToSend;
      vm.form.mailer = this.$store.state.mailerToUse;

      if (
        this.formData.field_products_multiselect === 0 &&
        vm.products.value !== undefined
      ) {
        vm.form.fields.products = [this.products.value];
      }

      if (vm.products.length > 0) {
        vm.form.fields.products = this.products.map((a) => a.id);
      }

      if (
        Object.keys(this.config.version_values).length === 1 &&
        this.zone !== "modal"
      ) {
        vm.form.fields.version = Object.keys(this.config.version_values)[0];
      }

      if (vm.form.fields.version === "" && this.zone === "modal") {
        vm.form.fields.version =
          this.$store.state.formVersion.version !== ""
            ? this.$store.state.formVersion.version
            : Object.keys(this.config.version_values)[0];
      }

      if (this.isCatalog) {
        vm.form.fields.location = this.$store.state.listingLocationId;
      }

      if (
        !vm.$route.query.hasOwnProperty("debug") &&
        !vm.$route.query.hasOwnProperty("teste")
      ) {
        if (
          this.checkBlockList(vm.form.name) ||
          this.checkBlockList(vm.form.email)
        ) {
          vm.form.test_lead = true;
        }
      }

      if (vm.form.test_lead || vm.$route.query.hasOwnProperty("debug")) {
        console.log("form_to_send =>", vm.form);
      }

      if (vm.form.fields.extrafields_json !== undefined) {
        vm.form.fields.extrafields_json = this.getValues();
      }

      if (vm.form.rgpd === true) {
        if (this.$store.state.formSubmitToServer) {
          console.log(vm.form);
        } else {
          axios
            .post(url, vm.form, {headers: {
        "Access-Control-Allow-Origin": true
      }})
            .then(function (response) {
              if (
                response.data === "registered" ||
                response.data === "debug_mode registered"
              ) {
                vm.success = true;
                vm.loadSpinner = false;
                if (!vm.$store.state.gtmTrigger) {
                  vm.$gtm.trackEvent({
                    event: "formSubmissionSuccess", // Event type [default = 'interaction'] (Optional)
                    category: "formSubmissionSuccess",
                    action: "formSubmissionSuccess",
                    label: "formSubmissionSuccess",
                    eventID: vm.$store.state.alid,
                    value: 0,
                    noninteraction: false, // Optional
                  });
                  window.alp.trackEvent({
                    event: "Lead",
                    data_layer: { email: vm.form.email },
                  });
                }
                vm.submitButtonDisabled = false;
                vm.$eventBus.$emit("success-form", true);
              } else {
                window.alp.trackEvent({ event: "formSubmissionError" });
                vm.errors = response.data.email;
                vm.loadSpinner = false;
                vm.submitButtonDisabled = false;
              }
            })
            .catch(function (error) {
              window.alp.trackEvent({ event: "formSubmissionError" });
              if (
                error.response.data === "nok" ||
                error.response.data === "generalerror"
              ) {
                vm.errors = ["Sem sucesso, tente novamente mais tarde."];
              } else if (error.response.data === "timeout") {
                vm.errors = ["Sem sucesso, tente novamente mais tarde."];
              } else {
                vm.errors = ["Sem sucesso, tente novamente mais tarde."];
              }

              vm.loadSpinner = false;
              vm.submitButtonDisabled = false;
            });
        }
      } else {
        vm.errors = [
          "Tem que aceitar os termos da campanha para submeter o formulário",
        ];
        vm.loadSpinner = false;
        vm.submitButtonDisabled = false;
      }
    },
  },
};
</script>
