<template>
  <div v-if="isDebug" class="debugger">
    <div
      v-b-toggle.sidebar-1
      class="debug-button d-flex justify-content-around"
    >
      <div>
        <i class="fas fa-bug"></i>
      </div>
      <div class="pl-2">
        <i class="fas fa-chevron-right"></i>
      </div>
    </div>
    <b-sidebar id="sidebar-1" title="Debugger" shadow>
      <div class="debug-content py-2">
        <ul class="pr-4">
          <!--					<li>-->
          <!--						<div class="form-group form-check">-->
          <!--							<input class="form-check-input" type="checkbox" v-model="debugForm.test_lead"-->
          <!--								   @change="setOptions()" id="test-lead">-->
          <!--							<label class="form-check-label" for="test-lead">Test Lead</label>-->
          <!--						</div>-->
          <!--					</li>-->
          <li>
            <div class="form-group form-check">
              <input
                class="form-check-input"
                type="checkbox"
                v-model="debugForm.disable_send_email"
                @change="setOptions()"
                id="disable_send_email"
              />
              <label class="form-check-label" for="disable_send_email"
                >Disable Send Email</label
              >
            </div>
          </li>
          <li>
            <div class="form-group form-check">
              <input
                class="form-check-input"
                type="checkbox"
                v-model="debugForm.fill_form"
                @change="setOptions()"
                id="fill_form"
              />
              <label class="form-check-label" for="fill_form"
                >Fill form with test values</label
              >
            </div>
          </li>
          <li>
            <div class="form-group form-check">
              <input
                class="form-check-input"
                type="checkbox"
                v-model="debugForm.gtm_trigger"
                @change="setOptions()"
                id="gtm_trigger"
              />
              <label class="form-check-label" for="gtm_trigger"
                >Cancel GTM Submit trigger</label
              >
            </div>
          </li>
          <li>
            <div class="form-group form-check">
              <input
                class="form-check-input"
                type="checkbox"
                v-model="debugForm.form_submit_to_server"
                @change="setOptions()"
                id="form_submit_to_server"
              />
              <label class="form-check-label" for="form_submit_to_server"
                >Cancel Form Submit to Server</label
              >
            </div>
          </li>
          <li>
            <div class="form-group">
              <label for="email_to_send">Force email to:</label>
              <input
                type="email"
                id="email_to_send"
                class="form-control"
                @change="setOptions()"
                v-model="debugForm.email_to_send"
              />
            </div>
          </li>
          <li>
            <div class="form-group">
              <label for="mailer">Force Mailer:</label>
              <select
                id="mailer"
                class="form-control"
                v-model="debugForm.mailer"
                @change="setOptions()"
              >
                <option value="" selected>Select option</option>
                <option value="smtp">Mailtrap test</option>
                <option value="mailgun">Mailgun</option>
              </select>
            </div>
          </li>
          <li>
            <div class="form-group">
              <label for="template_to_send">Force email template:</label>
              <select
                id="template_to_send"
                class="form-control"
                v-model="debugForm.template_to_send"
                @change="setOptions()"
              >
                <option value="" selected>Select option</option>
                <option value="default_template">default_template</option>
                <option value="default_non_auto_template">
                  default_non_auto_template
                </option>
                <option value="rrg_template">rrg_template</option>
                <option value="benecar_template">benecar_template</option>
                <option value="carplus_template">carplus_template</option>
                <option value="siva_template">siva_template</option>
                <option value="default_ssp_template">
                  default_ssp_template
                </option>
                <option value="ssp_custom_origin_template">
                  ssp_custom_origin_template
                </option>
              </select>
            </div>
          </li>
        </ul>
        <h5 class="m-3">Form settings</h5>
        <ul class="pr-4">
          <li>
            <div class="form-group form-check">
              <input
                class="form-check-input"
                type="checkbox"
                v-model="debugForm.fat_form"
                @change="setOptions()"
                id="fat_form"
              />
              <label class="form-check-label" for="fat_form"
                >Set Fat Form</label
              >
            </div>
          </li>
          <li>
            <div class="form-group">
              <label for="form_placement">Fom placement:</label>
              <select
                id="form_placement"
                class="form-control"
                v-model="debugForm.form_placement"
                @change="setOptions()"
              >
                <option value="" selected>Select option</option>
                <option value="top">Top</option>
                <option value="bottom">Bottom</option>
              </select>
            </div>
          </li>
          <li>
            <div class="form-group form-check">
              <input
                class="form-check-input"
                type="checkbox"
                v-model="debugForm.show_success_form"
                @change="setOptions()"
                id="show_success_form"
              />
              <label class="form-check-label" for="show_success_form"
                >Show success</label
              >
            </div>
          </li>
        </ul>
        <h5 class="m-3" v-if="sspData !== undefined && sspData.length > 0">
          SSP Data
        </h5>
        <ul
          class="pr-4"
          v-if="sspData !== undefined && sspData.length > 0"
          v-for="(item, i) in sspData"
          :key="i"
          :index="i"
        >
          <li style="font-size: 0.75rem"><b>Id:</b> {{ item.id }}</li>
          <li style="font-size: 0.75rem">
            <b>LP String:</b> {{ item.lp_address_string }}
          </li>
          <li style="font-size: 0.75rem">
            <b>Make:</b> {{ item.default_make }}
          </li>
          <li style="font-size: 0.75rem">
            <b>ClientID:</b> {{ item.client_id }}
          </li>
          <li style="font-size: 0.75rem">
            <b>AccessKey:</b> {{ item.access_key }}
          </li>
        </ul>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import { BSidebar, VBToggle } from "bootstrap-vue";
import Vue from "vue";

Vue.directive("b-toggle", VBToggle);
export default {
  name: "Debugger",
  components: {
    BSidebar,
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      sspData: this.config.ssp_data,
      isDebug: false,
      show: false,
      debugForm: {
        test_lead: false,
        disable_send_email: false,
        email_to_send: "",
        template_to_send: "",
        mailer: "",
        fat_form: false,
        form_placement: "",
        show_success_form: false,
        gtm_trigger: false,
        fill_form: false,
        form_submit_to_server: false,
      },
    };
  },
  async mounted() {
    try {
      await this.$store.dispatch(
        "getDebugMode",
        this.$route.query.hasOwnProperty("debug")
      );
      this.isDebug = this.$store.state.debugMode;
      this.testMode = this.$route.query.hasOwnProperty("teste");

      if (this.isDebug) {
        await this.$store.dispatch("testLead", this.isDebug);
        let element = document.getElementById("status-bar");
        element.style.display = "block";
        element.classList.add("warn");
        element.appendChild(
          document.createTextNode(
            'Debug Mode. Click on the "bug" on the left for options.'
          )
        );
      }

      if (this.testMode) {
        await this.$store.dispatch("testLead", this.testMode);
        let element = document.getElementById("status-bar");
        element.style.display = "block";
        element.classList.add("warn");
        element.appendChild(
          document.createTextNode(
            "Test Mode ON. Leads sent will be marked as test lead, but sent to the defined configuration email(s)."
          )
        );
      }
    } catch (error) {
      this.isDebug = false;
    }
  },
  methods: {
    setOptions() {
      if (this.debugForm.disable_send_email) {
        this.$store.dispatch("disableSendMail", true);
      }
      if (this.debugForm.gtm_trigger) {
        this.$store.dispatch("cancelGtmTrigger", this.debugForm.gtm_trigger);
      }
      if (this.debugForm.form_submit_to_server) {
        this.$store.dispatch(
          "cancelFormSubmitToServer",
          this.debugForm.form_submit_to_server
        );
      }
      if (this.debugForm.email_to_send !== "") {
        this.$store.dispatch("emailToSend", this.debugForm.email_to_send);
      }
      if (this.debugForm.template_to_send !== "") {
        this.$store.dispatch("templateToSend", this.debugForm.template_to_send);
      }

      this.$store.dispatch("fillForm", this.debugForm.fill_form);
      this.$store.dispatch("mailerToUse", this.debugForm.mailer);
      this.$store.dispatch("successForm", this.debugForm.show_success_form);
      this.$store.dispatch("fatForm", this.debugForm.fat_form);
      this.$store.dispatch("formPlacement", this.debugForm.form_placement);

      if (this.isDebug) {
        console.log(this.debugForm);
      }
    },
  },
};
</script>
