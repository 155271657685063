<template>
  <div class="row mt-3 mb-3 catalog-feed" id="catalog-feed">
    <div class="col-12 text-center mt-3 mb-2">
      <h2 v-html="moduleConfig.title"></h2>
    </div>
    <div
      class="col-12 mt-3"
      v-if="
        moduleConfig.show_filters !== undefined && moduleConfig.show_filters
      "
    >
      <form>
        <div class="row d-md-flex justify-content-md-center">
          <div
            class="col-12 col-md-3"
            v-if="
              moduleConfig.filters !== undefined &&
              moduleConfig.filters.sale_price
            "
          >
            <div class="form-group">
              <select
                class="form-control"
                v-model="sale_price"
                v-debounce:300ms="triggerChangeFilters"
                :disabled="disabled"
              >
                <option value="" selected>Preço</option>
                <option
                  :value="item"
                  v-for="(item, i) in carPrices"
                  :key="i"
                  :index="i"
                >
                  até
                  {{
                    item
                      | currency("€", 0, {
                        thousandsSeparator: ".",
                        symbolOnLeft: false,
                      })
                  }}
                </option>
              </select>
            </div>
          </div>
          <div
            class="col-12 col-md-3"
            v-if="
              moduleConfig.filters !== undefined &&
              moduleConfig.filters.fuel_type
            "
          >
            <div class="form-group">
              <select
                class="form-control"
                v-model="fuel_type_id"
                v-debounce:300ms="triggerChangeFilters"
                :disabled="disabled"
              >
                <option value="" selected>Combustível</option>
                <option
                  :value="i"
                  v-for="(item, i) in carFuelType"
                  :key="i"
                  :index="i"
                >
                  {{ item }}
                </option>
              </select>
            </div>
          </div>
          <div
            class="col-12 col-md-3"
            v-if="
              moduleConfig.filters !== undefined &&
              moduleConfig.filters.car_make
            "
          >
            <div class="form-group">
              <select
                class="form-control"
                id="car-make-id"
                v-model="car_make_id"
                v-debounce:300ms="triggerChangeFilters"
                :disabled="disabled"
              >
                <option value="" selected>Marca</option>
                <option
                  :value="i"
                  v-for="(item, i) in carMakes"
                  :key="i"
                  :index="i"
                >
                  {{ item === "Skoda" ? "ŠKODA" : item }}
                </option>
              </select>
            </div>
          </div>
          <div
            class="col-12 col-md-3"
            v-if="
              moduleConfig.filters !== undefined && moduleConfig.filters.models
            "
          >
            <div class="form-group">
              <select
                class="form-control"
                v-model="model_id"
                v-debounce:300ms="triggerChangeFilters"
                :disabled="disabled"
              >
                <option value="" selected>Modelo</option>
                <option
                  :value="item.model_id"
                  v-for="(item, i) in carModels"
                  :key="i"
                  :index="i"
                >
                  {{ item.ModelName }}
                </option>
              </select>
            </div>
          </div>

          <div
            class="col-12 col-md-3"
            v-if="
              moduleConfig.filters !== undefined &&
              moduleConfig.filters.addresses
            "
          >
            <div class="form-group">
              <select
                class="form-control"
                v-model="address_id"
                v-debounce:300ms="triggerChangeFilters"
                :disabled="disabled"
              >
                <option value="" selected>Localização</option>
                <option
                  :value="i"
                  v-for="(item, i) in carAddresses"
                  :key="i"
                  :index="i"
                >
                  {{ item }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div
      class="col-12 col-md-6 col-lg-4 mt-3 mb-3"
      v-for="(listing, indx) in feed"
      :key="indx"
      :index="indx"
    >
      <div class="card" v-if="listing.listing_status_id === 1">
        <a
          @click="
            setUrlQuery(
              listing.url,
              `card-${indx}-0`,
              listing.Address.id,
              `${setMakeName(listing.CarNexus.car_makes.MakeName)}-${
                listing.CarNexus.ModelName
              } ${
                listing.CarMainData.RegistrationPlate !== null
                  ? listing.CarMainData.RegistrationPlate
                  : ''
              }`,
              listing
            )
          "
          rel="noopener"
          :id="`card-${indx}-0`"
        >
          <img v-lazy="setListingImg(listing)" class="card-img-top" alt="" />
          <div class="card-body text-center">
            <h5 class="card-title">
              {{
                listing.CarNexus.car_makes.MakeName === "Skoda"
                  ? "ŠKODA"
                  : listing.CarNexus.car_makes.MakeName
              }}
              {{ listing.CarNexus.ModelName }}
            </h5>
            <p class="card-sub-title">
              {{ listing.CarMainData.Version }}
              {{ setYear(listing.CarMainData.RegistrationDate) }}
            </p>
            <p class="card-sub-title">
              <span v-if="parseInt(listing.CarMainData.EngineSize) > 1"
                >{{ listing.CarMainData.EngineSize }} cc | </span
              >{{ listing.CarMainData.HorsePower }} cv |
              {{ listing.CarMainData.CurrentKms }} Kms
            </p>

            <p
              v-if="listing.seller_id === 415 || listing.seller_id === 627"
              style="margin-top: -10px; font-weight: 600"
              class="mb-3 card-sub-title"
            >
              <i class="fa-solid fa-location-dot"></i>
              {{
                listing.Address.location_cp.Locality === "Lavradio"
                  ? "Barreiro"
                  : listing.Address.location_cp.Locality
              }}
            </p>

            <!-- card-price promo start -->
            <div
              class="card-price-promo"
              v-if="
                listing.IsPromoPrice === 1 &&
                config.catalog.campaign_is_car_promo === 1
              "
            >
              <div class="d-flex justify-content-center">
                <div class="sizing left">
                  <p
                    class="price-before-text"
                    v-html="config.catalog.price_text"
                  ></p>
                  <div class="price-before">
                    {{
                      listing.SalePrice
                        | currency("€", 0, {
                          thousandsSeparator: ".",
                          symbolOnLeft: false,
                        })
                    }}<span
                      v-if="config.catalog.show_tax_tag === 1"
                      style="font-size: 0.8rem"
                      >+IVA</span
                    >
                  </div>
                </div>
                <div class="sizing">
                  <p class="after" v-html="config.catalog.promo_price_text"></p>
                  <div class="price">
                    {{
                      listing.PromoPrice
                        | currency("€", 0, {
                          thousandsSeparator: ".",
                          symbolOnLeft: false,
                        })
                    }}<span
                      v-if="config.catalog.show_tax_tag === 1"
                      style="font-size: 0.8rem"
                      >+IVA</span
                    >
                  </div>
                </div>
              </div>

              <div
                class="d-flex justify-content-center"
                v-if="
                  listing.Discount !== null &&
                  listing.Discount !== undefined &&
                  listing.Discount > 0
                "
              >
                <div class="sizing">
                  <p class="after">Poupe</p>
                  <div
                    style="
                      font-size: 1.8rem;
                      font-weight: 700;
                      margin-top: 10px;
                    "
                  >
                    -
                    {{
                      listing.Discount
                        | currency("€", 0, {
                          thousandsSeparator: ".",
                          symbolOnLeft: false,
                        })
                    }}
                  </div>
                </div>
              </div>
            </div>
            <!-- card-price promo end -->
            <!-- card-price not promo -->
            <div
              class="card-price"
              v-if="
                (listing.IsPromoPrice !== 1 &&
                  config.catalog.campaign_is_car_promo === 1) ||
                config.catalog.campaign_is_car_promo !== 1
              "
            >
              <p v-html="config.catalog.promo_price_text"></p>
              <div class="price" v-if="listing.SalePrice !== 0">
                {{
                  listing.SalePrice
                    | currency("€", 0, {
                      thousandsSeparator: ".",
                      symbolOnLeft: false,
                    })
                }}<span
                  v-if="config.catalog.show_tax_tag === 1"
                  style="font-size: 0.8rem"
                  >+IVA</span
                >
              </div>
              <div
                class="price"
                v-if="listing.SalePrice === 0"
                style="font-size: 1.1rem; padding: 7px"
              >
                Sob Consulta
              </div>
            </div>
            <!-- card-price not promo end -->
            <a
              @click="
                setUrlQuery(
                  listing.url,
                  `card-${indx}-0`,
                  listing.Address.id,
                  `${setMakeName(listing.CarNexus.car_makes.MakeName)}-${
                    listing.CarNexus.ModelName
                  } ${
                    listing.CarMainData.RegistrationPlate !== null
                      ? listing.CarMainData.RegistrationPlate
                      : ''
                  }`
                )
              "
              class="btn btn-primary btn-block"
              rel="noopener"
              :id="`card-${indx}-1`"
            >
              {{ moduleConfig.show_promo_settings.button_text }}</a
            >
          </div>
        </a>
      </div>
      <div class="card" v-if="listing.listing_status_id === 33">
        <img v-lazy="setListingImg(listing)" class="card-img-top" alt="" />
        <div class="card-body text-center">
          <h5 class="card-title">
            {{
              listing.CarNexus.car_makes.MakeName === "Skoda"
                ? "ŠKODA"
                : listing.CarNexus.car_makes.MakeName
            }}
            {{ listing.CarNexus.ModelName }}
          </h5>
          <p class="card-sub-title">
            {{ listing.CarMainData.Version }}
            {{ setYear(listing.CarMainData.RegistrationDate) }}
          </p>
          <p class="card-sub-title">
            <span v-if="parseInt(listing.CarMainData.EngineSize) > 1"
              >{{ listing.CarMainData.EngineSize }} cc | </span
            >{{ listing.CarMainData.HorsePower }} cv |
            {{ listing.CarMainData.CurrentKms }} Kms
          </p>
          <div class="card-price">
            <div class="price">
              {{
                listing.SalePrice
                  | currency("€", 0, {
                    thousandsSeparator: ".",
                    symbolOnLeft: false,
                  })
              }}
            </div>
            <p class="m-0" style="font-size: 1.1rem; letter-spacing: 1px">
              Vendido
            </p>
          </div>
        </div>
      </div>
    </div>

    <infinite-loading @infinite="infiniteHandler" :identifier="infiniteId">
      <div slot="no-more"></div>
      <div slot="no-results"></div>
    </infinite-loading>

    <div class="col-12 text-center mt-3 mb-3" v-if="showMoreCars">
      <a
        :href="currentUrl"
        target="_blank"
        style="color: #000 !important; text-decoration: underline"
        >Ver todas as viaturas em campanha</a
      >
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  name: "CatalogFeed",
  components: {},
  props: {
    catalogData: {
      type: Array,
      required: true,
    },
    fuelType: {
      type: Object,
      required: true,
    },
    makes: {
      type: Object,
      required: true,
    },
    models: {
      type: Array,
      required: true,
    },
    addresses: {
      type: Object,
      required: true,
    },
    prices: {
      type: Array,
      required: true,
    },
    moduleConfig: {
      type: Object,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
    type: {
      default: "",
      type: String,
      required: true,
    },
  },
  data() {
    return {
      feed: this.catalogData,
      page: 2,
      carFuelType: this.fuelType,
      carMakes: this.makes,
      carModels: Array,
      carPrices: this.prices,
      carAddresses: this.addresses,
      fuel_type_id: "",
      car_make_id: "",
      model_id: "",
      sale_price: "",
      address_id: "",
      infiniteId: +new Date(),
      disabled: false,
      showMoreCars: false,
      currentUrl: this.$store.state.currentUrl,
    };
  },
  mounted() {
    if (Object.entries(this.makes).length === 1) {
      this.car_make_id = Object.entries(this.makes)[0];
      this.setModels();
    }

    this.showMoreCars =
      this.$route.query.car_make_id !== undefined &&
      this.$route.query.car_make_id !== "";

    this.car_make_id =
      this.$route.query.car_make_id !== undefined &&
      this.$route.query.car_make_id !== ""
        ? this.$route.query.car_make_id
        : "";

    this.model_id =
      this.$route.query.model_id !== undefined &&
      this.$route.query.model_id !== ""
        ? this.$route.query.model_id
        : "";
  },
  methods: {
    setYear(date) {
      return moment(date, "YYYY-MM-DD hh:mm:ss").format("YYYY");
    },
    setUrlQuery(url, event, addressId, version = "") {
      if (this.type !== "modal") {
        let routeQuery = this.$route.query;
        let query = {};

        for (let [k, v] of Object.entries(routeQuery)) {
          if (k !== "debug" && k !== "preview" && k !== "teste") {
            query[k] = v;
          }
        }

        if (Object.entries(query).length > 0) {
          let serializeObj = Object.entries(query)
            .map(([key, val]) => `${key}=${val}`)
            .join("&");
          document.getElementById(
            event
          ).href = `${this.$store.state.currentUrl}/viaturas/${url}?${serializeObj}`;
          return;
        }

        document.getElementById(
          event
        ).href = `${this.$store.state.currentUrl}/viaturas/${url}`;
        return;
      }

      if (this.type === "modal") {
        this.$eventBus.$emit("open-modal", { version, address_id: addressId });
      }
    },
    infiniteHandler($state) {
      this.disabled = true;
      let vm = this;
      axios
        .get(
          `${process.env.VUE_APP_API_URL}/api/ad-engine/landing-page/feed/${this.config.catalog.id}`,
          {
            params: {
              page: this.page,
              fuel_type_id:
                vm.fuel_type_id !== ""
                  ? vm.fuel_type_id
                  : vm.$route.query.fuel_type_id,
              car_make_id:
                vm.car_make_id !== ""
                  ? vm.car_make_id
                  : vm.$route.query.car_make_id,
              address_id:
                vm.address_id !== ""
                  ? vm.address_id
                  : vm.$route.query.address_id,
              model_id:
                vm.model_id !== "" ? vm.model_id : vm.$route.query.model_id,
              sale_price:
                vm.sale_price !== ""
                  ? vm.sale_price
                  : vm.$route.query.sale_price,
              order: vm.$route.query.order ? vm.$route.query.order : "",
            },
          }
        )
        .then(function (response) {
          vm.page += 1;
          if (response.data.results.data.length) {
            response.data.results.data.forEach((listing) => {
              vm.feed.push(listing);
            });
            $state.loaded();
            vm.setMakes();
            vm.setModels();
          } else {
            $state.complete();
          }
          vm.disabled = false;
        })
        .catch(function (error) {
          console.log(error);
          $state.complete();
          vm.disabled = false;
        });
    },
    setListingImg(listing) {
      if (process.env.NODE_ENV === "development") {
        return "https://www.rotauto.com/assets/img/imagemOG_rotauto2020.jpg";
      }
      return `${process.env.VUE_APP_ROTAUTO_IMG_SOURCE}/${listing.uuid}/${
        listing.ItemImages.main !== undefined ? listing.ItemImages.main[0] : ""
      }`;
    },
    triggerChangeFilters(val, e) {
      if (e.target.id === "car-make-id") {
        this.model_id = "";
      }
      this.search();
    },
    setModels() {
      let models = this.models.filter((e) => {
        return parseInt(e.car_make_id) === parseInt(this.car_make_id);
      });

      let sorted = models.sort(function (a, b) {
        if (a.ModelName < b.ModelName) {
          return -1;
        }
        if (a.ModelName > b.ModelName) {
          return 1;
        }
        return 0;
      });

      this.carModels = sorted;
    },
    setMakeName(make) {
      let str = "";
      const makes = {
        ABA: "Abarth",
        ALF: "Alfa Romeo",
        ALP: "Alpine",
        AUD: "Audi",
        BMW: "BMW",
        CIT: "Citroën",
        CUP: "CUPRA",
        DAC: "Dacia",
        DUC: "DUCATI",
        CDS: "DS",
        FIA: "Fiat",
        FOR: "Ford",
        HON: "Honda",
        HYU: "Hyundai",
        JAG: "Jaguar",
        JEE: "Jeep",
        KIA: "Kia",
        LRR: "Land Rover",
        LEX: "Lexus",
        MAZ: "Mazda",
        MER: "Mercedes - Benz",
        MIN: "Mini",
        MIT: "Mitsubishi",
        NIS: "Nissan",
        OPE: "Opel",
        PEU: "Peugeot",
        REN: "Renault",
        SEA: "Seat",
        SKO: "Skoda",
        SMA: "Smart",
        TES: "Tesla",
        TOY: "Toyota",
        VKW: "Volkswagen",
        VOL: "Volvo",
        VWC: "VW Comerciais",
        MRAD: "Motorrad",
        HUSQ: "Husqvarna",
        KTM: "KTM",
        MG: "MG",
        ZERO: "",
      };

      for (const [key, value] of Object.entries(makes)) {
        if (make.toLowerCase() === value.toLowerCase()) {
          str = key;
        }
      }

      return str;
    },
    setMakes() {
      if (this.fuel_type_id === "") {
        this.carMakes = this.makes;
      } else {
        let filteredMake = this.feed.filter((e) => {
          return (
            parseInt(e.CarMainData.fuel_type_id) === parseInt(this.fuel_type_id)
          );
        });

        let makes = {};
        filteredMake.forEach((e) => {
          makes[e.CarNexus.car_make_id] =
            e.CarNexus.car_makes.MakeName === "Skoda"
              ? "ŠKODA"
              : e.CarNexus.car_makes.MakeName;
        });

        this.carMakes = makes;
      }
    },
    search() {
      this.feed = [];
      this.page = 1;
      this.infiniteId += 1;
    },
  },
};
</script>
