const blockListMixin = {
	methods : {
		checkBlockList(field) {
			let blockList = [
				'test', 'teste', 'rotauto'
			];

			return blockList.some(substring => field.includes(substring));
		}
	}
};

export default blockListMixin;
