<template>
	<div class="container addressv1">
		<div class="row">
			<div class="col-12">
				<div class="d-flex justify-content-between">
					<div class="address d-flex justify-content-start">
						<div>
							<i class="fas fa-map-marker-alt pr-2 fa-2x address-icon"></i>
						</div>
						<div>
							<p v-for="(location, indx) in config">
								<strong v-html="location.StandName"></strong>
								<br>
								<span
									class="address-text-span"
									v-html="location.Address + ', ' + location.CP4 + '-' + location.CP3 + ' ' + location.Locality"></span>
								<br>
								<span v-html="location.schedules"></span>
								<br>
								<span v-if="location.PhoneOne !== null">Telefone: </span><a
								style="text-decoration: underline" @click="triggerGTM()"
								:href="'tel:' + location.PhoneOne">{{ location.PhoneOne }}</a>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name       : 'AddressV1',
	components : {},
	props      : {
		config : {
			type     : Array,
			required : true,
		}
	},
	data() {
		return {};
	},
	mounted() {
	},
	methods : {
		triggerGTM() {
			this.$gtm.trackEvent({
				event          : 'phoneClicked', // Event type [default = 'interaction'] (Optional)
				category       : 'phoneClicked',
				action         : 'phoneClicked',
				label          : 'phoneClicked',
				eventID        : this.$store.state.alid,
				value          : 0,
				noninteraction : false // Optional
			});
			window.alp.trackEvent({ 'event' : 'phoneClicked' });
		},
	}
};
</script>
