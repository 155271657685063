<template>
	<header>
		<nav class="navbar navbar-light bg-light">
			<div class="container">
				<a class="navbar-brand" v-if="logoLeftSrc !== null && logoLink === 0">
					<img class="logo-left" v-lazy="setImgFallback(logoLeftSrc)" :alt="logoLeftAlt" width="100%">
				</a>
				<a class="navbar-brand" v-if="logoLeftSrc !== null && logoLink !== 0" target="_blank"
				   :href="config.redirect_url">
					<img class="logo-left" v-lazy="setImgFallback(logoLeftSrc)" :alt="logoLeftAlt" width="100%">
				</a>
				<div class="d-none d-lg-block">
					<ul class="navbar-nav mr-auto">
						<li class="nav-item active">
							<a class="navbar-brand" v-if="logoRightSrc !== null">
								<img class="logo-right" v-lazy="setImgFallback(logoRightSrc)" :alt="logoRightAlt"
									 width="100%">
							</a>
						</li>
					</ul>
				</div>
			</div>
		</nav>
	</header>
</template>

<script>
export default {
	name  : 'Header',
	props : {
		config : {
			type     : Object,
			required : true,
		}
	},
	data() {
		return {
			logoRightSrc : this.config.logo_right,
			logoRightAlt : this.config.nav_logo_right_alt,
			logoLeftSrc  : this.config.logo_left,
			logoLeftAlt  : this.config.nav_logo_left_alt,
			logoLink     : this.config.logo_link,
		};
	},
	methods : {
		setImgFallback(img) {
			const { userAgent } = navigator;

			if (userAgent.includes('Firefox/')) {
				return img;
			} else if (userAgent.includes('Edg/')) {
				return img;
			} else if (userAgent.includes('Chrome/')) {
				return img;
			} else if (userAgent.includes('Safari/')) {
				return img.replace('/conversions', '').replace('.webp', '.png');
			}

			return img;
		}
	},
};
</script>
