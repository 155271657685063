<template>
	<div class="row gallery" id="gallery">
		<div v-for="(image, i) in images" :key="i" @click="index = i" class="img-container p-1"
			 :class="{'col-12 col-md-6 col-lg-6' : grid === 2, 'col-lg-4 col-md-6 col-12' : grid === 3, 'col-md-6 col-lg-3 col-12' : grid === 4}">
			<img class="img-fluid" v-lazy="setImgFallback(image)">
		</div>
		<vue-gallery-slideshow :images="images" :index="index" @close="index = null"></vue-gallery-slideshow>
	</div>
</template>

<script>
import setImgFallbackMixin from '@/common/set-img-fallback-mixin';
import VueGallerySlideshow from 'vue-gallery-slideshow';

export default {
	name       : 'Gallery',
	components : { VueGallerySlideshow },
	mixins     : [setImgFallbackMixin],
	props      : {
		galleryImages : {
			type     : Array,
			required : true,
		},
		size          : {
			type     : Number,
			required : true,
		}
	},
	data() {
		return {
			grid   : this.size,
			images : this.galleryImages,
			index  : null
		};
	}
};
</script>
