<template>
	<div class="row">
		<div class="col-12" v-for="(slideshow, i) in jsonConfig.slideshowv2 " :key="i" :index="i">
			<div class="row">
				<div class="col-12 text-center mt-5 mb-3">
					<h2 style="font-size:1.2rem" v-html="slideshow.title"></h2>
				</div>
				<hooper :settings="hooperSettings" :infinite-scroll="true" :auto-play="true" :play-speed="2000">
					<slide v-for="(slide, indx) in slideshow.images" :key="indx" :index="indx">
						<a :href="setUrlQuery(slide.link)" :target="setBlank(slide.link)" rel="noopener"><img
							:src="setImgFallback(slide.img)" alt="" class="img-fluid"
							@click="changeVersion(slide.version_id)"></a>
					</slide>
					<hooper-navigation
						v-if="slideshow.show_navigation !== undefined && slideshow.show_navigation"
						slot="hooper-addons"></hooper-navigation>
					<hooper-pagination v-if="slideshow.show_pagination" slot="hooper-addons"
									   style="margin-bottom: 1.5rem;"></hooper-pagination>
				</hooper>
			</div>
			<div class="d-flex justify-content-center">
				<a class="btn btn-primary slideshow-btn"
				   :href="slideshow.button_id_location !== undefined ? slideshow.button_id_location : '#form'"
				   v-html="slideshow.button"
				   @click="changeVersion(slideshow.images[0].version_id)"></a>
			</div>
		</div>
	</div>
</template>

<script>
import setImgFallbackMixin                                                             from '@/common/set-img-fallback-mixin';
import {Hooper, Navigation as HooperNavigation, Pagination as HooperPagination, Slide} from 'hooper';

import 'hooper/dist/hooper.css';

export default {
	name       : 'SlideshowV2',
	components : {
		Hooper,
		HooperNavigation,
		HooperPagination,
		Slide
	},
	mixins     : [setImgFallbackMixin],
	props      : {
		jsonConfig : {
			type     : Object,
			required : true
		}
	},
	data() {
		return {
			hooperSettings : {
				centerMode   : true,
				wheelControl : false,
				mouseDrag    : false,
				breakpoints  : {
					300  : {
						centerMode  : true,
						itemsToShow : 1
					},
					768  : {
						centerMode  : true,
						itemsToShow : 1
					},
					1024 : {
						centerMode  : true,
						itemsToShow : 2,
					}
				}
			},
		};
	},
	created() {
	},
	methods : {
		setUrlQuery(url) {
			let routeQuery = this.$route.query;
			let query      = {};

			for (let [k, v] of Object.entries(routeQuery)) {
				if (k !== 'debug' && k !== 'preview' && k !== 'teste') {
					query[k] = v;
				}
			}

			if (Object.entries(query).length > 0) {
				let serializeObj = Object.entries(query).map(([key, val]) => `${key}=${val}`).join('&');
				if (url.indexOf('#') > -1 && url.indexOf('http') < 0) {
					return `${url}`;
				}

				return `${url}?${serializeObj}`
			}
			return url;
		},
		setBlank(link) {
			if (link.indexOf('#') > -1 && link.indexOf('http') < 0) {
				return '_self';
			}
			return '_blank';
		},
		changeVersion(val) {
			if (val !== undefined) {
				this.$eventBus.$emit('change-version', val);
			}
		},
	}
};
</script>
