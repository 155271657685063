var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mt-3 mb-3 catalog-feed",attrs:{"id":"catalog-feed"}},[_c('div',{staticClass:"col-12 text-center mt-3 mb-2"},[_c('h2',{domProps:{"innerHTML":_vm._s(_vm.moduleConfig.title)}})]),(
      _vm.moduleConfig.show_filters !== undefined && _vm.moduleConfig.show_filters
    )?_c('div',{staticClass:"col-12 mt-3"},[_c('form',[_c('div',{staticClass:"row d-md-flex justify-content-md-center"},[(
            _vm.moduleConfig.filters !== undefined &&
            _vm.moduleConfig.filters.sale_price
          )?_c('div',{staticClass:"col-12 col-md-3"},[_c('div',{staticClass:"form-group"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.sale_price),expression:"sale_price"},{name:"debounce",rawName:"v-debounce:300ms",value:(_vm.triggerChangeFilters),expression:"triggerChangeFilters",arg:"300ms"}],staticClass:"form-control",attrs:{"disabled":_vm.disabled},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.sale_price=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":"","selected":""}},[_vm._v("Preço")]),_vm._l((_vm.carPrices),function(item,i){return _c('option',{key:i,attrs:{"index":i},domProps:{"value":item}},[_vm._v(" até "+_vm._s(_vm._f("currency")(item,"€", 0, { thousandsSeparator: ".", symbolOnLeft: false, }))+" ")])})],2)])]):_vm._e(),(
            _vm.moduleConfig.filters !== undefined &&
            _vm.moduleConfig.filters.fuel_type
          )?_c('div',{staticClass:"col-12 col-md-3"},[_c('div',{staticClass:"form-group"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.fuel_type_id),expression:"fuel_type_id"},{name:"debounce",rawName:"v-debounce:300ms",value:(_vm.triggerChangeFilters),expression:"triggerChangeFilters",arg:"300ms"}],staticClass:"form-control",attrs:{"disabled":_vm.disabled},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.fuel_type_id=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":"","selected":""}},[_vm._v("Combustível")]),_vm._l((_vm.carFuelType),function(item,i){return _c('option',{key:i,attrs:{"index":i},domProps:{"value":i}},[_vm._v(" "+_vm._s(item)+" ")])})],2)])]):_vm._e(),(
            _vm.moduleConfig.filters !== undefined &&
            _vm.moduleConfig.filters.car_make
          )?_c('div',{staticClass:"col-12 col-md-3"},[_c('div',{staticClass:"form-group"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.car_make_id),expression:"car_make_id"},{name:"debounce",rawName:"v-debounce:300ms",value:(_vm.triggerChangeFilters),expression:"triggerChangeFilters",arg:"300ms"}],staticClass:"form-control",attrs:{"id":"car-make-id","disabled":_vm.disabled},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.car_make_id=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":"","selected":""}},[_vm._v("Marca")]),_vm._l((_vm.carMakes),function(item,i){return _c('option',{key:i,attrs:{"index":i},domProps:{"value":i}},[_vm._v(" "+_vm._s(item === "Skoda" ? "ŠKODA" : item)+" ")])})],2)])]):_vm._e(),(
            _vm.moduleConfig.filters !== undefined && _vm.moduleConfig.filters.models
          )?_c('div',{staticClass:"col-12 col-md-3"},[_c('div',{staticClass:"form-group"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.model_id),expression:"model_id"},{name:"debounce",rawName:"v-debounce:300ms",value:(_vm.triggerChangeFilters),expression:"triggerChangeFilters",arg:"300ms"}],staticClass:"form-control",attrs:{"disabled":_vm.disabled},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.model_id=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":"","selected":""}},[_vm._v("Modelo")]),_vm._l((_vm.carModels),function(item,i){return _c('option',{key:i,attrs:{"index":i},domProps:{"value":item.model_id}},[_vm._v(" "+_vm._s(item.ModelName)+" ")])})],2)])]):_vm._e(),(
            _vm.moduleConfig.filters !== undefined &&
            _vm.moduleConfig.filters.addresses
          )?_c('div',{staticClass:"col-12 col-md-3"},[_c('div',{staticClass:"form-group"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.address_id),expression:"address_id"},{name:"debounce",rawName:"v-debounce:300ms",value:(_vm.triggerChangeFilters),expression:"triggerChangeFilters",arg:"300ms"}],staticClass:"form-control",attrs:{"disabled":_vm.disabled},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.address_id=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":"","selected":""}},[_vm._v("Localização")]),_vm._l((_vm.carAddresses),function(item,i){return _c('option',{key:i,attrs:{"index":i},domProps:{"value":i}},[_vm._v(" "+_vm._s(item)+" ")])})],2)])]):_vm._e()])])]):_vm._e(),_vm._l((_vm.feed),function(listing,indx){return _c('div',{key:indx,staticClass:"col-12 col-md-6 col-lg-4 mt-3 mb-3",attrs:{"index":indx}},[(listing.listing_status_id === 1)?_c('div',{staticClass:"card"},[_c('a',{attrs:{"rel":"noopener","id":`card-${indx}-0`},on:{"click":function($event){_vm.setUrlQuery(
            listing.url,
            `card-${indx}-0`,
            listing.Address.id,
            `${_vm.setMakeName(listing.CarNexus.car_makes.MakeName)}-${
              listing.CarNexus.ModelName
            } ${
              listing.CarMainData.RegistrationPlate !== null
                ? listing.CarMainData.RegistrationPlate
                : ''
            }`,
            listing
          )}}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.setListingImg(listing)),expression:"setListingImg(listing)"}],staticClass:"card-img-top",attrs:{"alt":""}}),_c('div',{staticClass:"card-body text-center"},[_c('h5',{staticClass:"card-title"},[_vm._v(" "+_vm._s(listing.CarNexus.car_makes.MakeName === "Skoda" ? "ŠKODA" : listing.CarNexus.car_makes.MakeName)+" "+_vm._s(listing.CarNexus.ModelName)+" ")]),_c('p',{staticClass:"card-sub-title"},[_vm._v(" "+_vm._s(listing.CarMainData.Version)+" "+_vm._s(_vm.setYear(listing.CarMainData.RegistrationDate))+" ")]),_c('p',{staticClass:"card-sub-title"},[(parseInt(listing.CarMainData.EngineSize) > 1)?_c('span',[_vm._v(_vm._s(listing.CarMainData.EngineSize)+" cc | ")]):_vm._e(),_vm._v(_vm._s(listing.CarMainData.HorsePower)+" cv | "+_vm._s(listing.CarMainData.CurrentKms)+" Kms ")]),(listing.seller_id === 415 || listing.seller_id === 627)?_c('p',{staticClass:"mb-3 card-sub-title",staticStyle:{"margin-top":"-10px","font-weight":"600"}},[_c('i',{staticClass:"fa-solid fa-location-dot"}),_vm._v(" "+_vm._s(listing.Address.location_cp.Locality === "Lavradio" ? "Barreiro" : listing.Address.location_cp.Locality)+" ")]):_vm._e(),(
              listing.IsPromoPrice === 1 &&
              _vm.config.catalog.campaign_is_car_promo === 1
            )?_c('div',{staticClass:"card-price-promo"},[_c('div',{staticClass:"d-flex justify-content-center"},[_c('div',{staticClass:"sizing left"},[_c('p',{staticClass:"price-before-text",domProps:{"innerHTML":_vm._s(_vm.config.catalog.price_text)}}),_c('div',{staticClass:"price-before"},[_vm._v(" "+_vm._s(_vm._f("currency")(listing.SalePrice,"€", 0, { thousandsSeparator: ".", symbolOnLeft: false, }))),(_vm.config.catalog.show_tax_tag === 1)?_c('span',{staticStyle:{"font-size":"0.8rem"}},[_vm._v("+IVA")]):_vm._e()])]),_c('div',{staticClass:"sizing"},[_c('p',{staticClass:"after",domProps:{"innerHTML":_vm._s(_vm.config.catalog.promo_price_text)}}),_c('div',{staticClass:"price"},[_vm._v(" "+_vm._s(_vm._f("currency")(listing.PromoPrice,"€", 0, { thousandsSeparator: ".", symbolOnLeft: false, }))),(_vm.config.catalog.show_tax_tag === 1)?_c('span',{staticStyle:{"font-size":"0.8rem"}},[_vm._v("+IVA")]):_vm._e()])])]),(
                listing.Discount !== null &&
                listing.Discount !== undefined &&
                listing.Discount > 0
              )?_c('div',{staticClass:"d-flex justify-content-center"},[_c('div',{staticClass:"sizing"},[_c('p',{staticClass:"after"},[_vm._v("Poupe")]),_c('div',{staticStyle:{"font-size":"1.8rem","font-weight":"700","margin-top":"10px"}},[_vm._v(" - "+_vm._s(_vm._f("currency")(listing.Discount,"€", 0, { thousandsSeparator: ".", symbolOnLeft: false, }))+" ")])])]):_vm._e()]):_vm._e(),(
              (listing.IsPromoPrice !== 1 &&
                _vm.config.catalog.campaign_is_car_promo === 1) ||
              _vm.config.catalog.campaign_is_car_promo !== 1
            )?_c('div',{staticClass:"card-price"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.config.catalog.promo_price_text)}}),(listing.SalePrice !== 0)?_c('div',{staticClass:"price"},[_vm._v(" "+_vm._s(_vm._f("currency")(listing.SalePrice,"€", 0, { thousandsSeparator: ".", symbolOnLeft: false, }))),(_vm.config.catalog.show_tax_tag === 1)?_c('span',{staticStyle:{"font-size":"0.8rem"}},[_vm._v("+IVA")]):_vm._e()]):_vm._e(),(listing.SalePrice === 0)?_c('div',{staticClass:"price",staticStyle:{"font-size":"1.1rem","padding":"7px"}},[_vm._v(" Sob Consulta ")]):_vm._e()]):_vm._e(),_c('a',{staticClass:"btn btn-primary btn-block",attrs:{"rel":"noopener","id":`card-${indx}-1`},on:{"click":function($event){_vm.setUrlQuery(
                listing.url,
                `card-${indx}-0`,
                listing.Address.id,
                `${_vm.setMakeName(listing.CarNexus.car_makes.MakeName)}-${
                  listing.CarNexus.ModelName
                } ${
                  listing.CarMainData.RegistrationPlate !== null
                    ? listing.CarMainData.RegistrationPlate
                    : ''
                }`
              )}}},[_vm._v(" "+_vm._s(_vm.moduleConfig.show_promo_settings.button_text))])])])]):_vm._e(),(listing.listing_status_id === 33)?_c('div',{staticClass:"card"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.setListingImg(listing)),expression:"setListingImg(listing)"}],staticClass:"card-img-top",attrs:{"alt":""}}),_c('div',{staticClass:"card-body text-center"},[_c('h5',{staticClass:"card-title"},[_vm._v(" "+_vm._s(listing.CarNexus.car_makes.MakeName === "Skoda" ? "ŠKODA" : listing.CarNexus.car_makes.MakeName)+" "+_vm._s(listing.CarNexus.ModelName)+" ")]),_c('p',{staticClass:"card-sub-title"},[_vm._v(" "+_vm._s(listing.CarMainData.Version)+" "+_vm._s(_vm.setYear(listing.CarMainData.RegistrationDate))+" ")]),_c('p',{staticClass:"card-sub-title"},[(parseInt(listing.CarMainData.EngineSize) > 1)?_c('span',[_vm._v(_vm._s(listing.CarMainData.EngineSize)+" cc | ")]):_vm._e(),_vm._v(_vm._s(listing.CarMainData.HorsePower)+" cv | "+_vm._s(listing.CarMainData.CurrentKms)+" Kms ")]),_c('div',{staticClass:"card-price"},[_c('div',{staticClass:"price"},[_vm._v(" "+_vm._s(_vm._f("currency")(listing.SalePrice,"€", 0, { thousandsSeparator: ".", symbolOnLeft: false, }))+" ")]),_c('p',{staticClass:"m-0",staticStyle:{"font-size":"1.1rem","letter-spacing":"1px"}},[_vm._v(" Vendido ")])])])]):_vm._e()])}),_c('infinite-loading',{attrs:{"identifier":_vm.infiniteId},on:{"infinite":_vm.infiniteHandler}},[_c('div',{attrs:{"slot":"no-more"},slot:"no-more"}),_c('div',{attrs:{"slot":"no-results"},slot:"no-results"})]),(_vm.showMoreCars)?_c('div',{staticClass:"col-12 text-center mt-3 mb-3"},[_c('a',{staticStyle:{"color":"#000 !important","text-decoration":"underline"},attrs:{"href":_vm.currentUrl,"target":"_blank"}},[_vm._v("Ver todas as viaturas em campanha")])]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }