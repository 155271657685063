<template>
	<div class="container addressv2">
		<div class="row">
			<div class="col-12 text-center pt-5">
			</div>
		</div>
		<div class="row pt-4 pb-4 schedule" v-for="(location, indx) in config">
			<div class="col-12 col-lg-3">
				<div class="row pb-3">
					<div class="col-2">
						<p><i class="fas fa-map-marker-alt pr-2"></i></p>
					</div>
					<div class="col-10">
						<p>
							<span class="stand-name" v-html="location.StandName"></span>
							<br>
							<span
								v-html="location.Address + ', ' + location.CP4 + '-' + location.CP3 + ' ' + location.Locality"></span>
						</p>
					</div>
				</div>
				<div class="row pb-3">
					<div class="col-2">
						<p><i class="fas fa-phone pr-2"></i></p>
					</div>
					<div class="col-10">
						<p>
							<a style="text-decoration: underline" :href="'tel:' + location.PhoneOne"
							   @click="triggerGTM()"><span v-html="dividePhone(location.PhoneOne)"></span></a>
						</p>
					</div>
				</div>
				<div class="row pb-3">
					<div class="col-2">
						<p><i class="far fa-clock pr-2"></i></p>
					</div>
					<div class="col-10">
						<p><strong>Horário</strong></p>
						<p><span v-html="location.schedules"></span></p>
					</div>
				</div>
			</div>
			<div class="col-12 col-lg-9">
				<a :href="location.google_maps_link" target="_blank" rel="noopener">
					<img
						:src="setMapImg(location.address_id , 'desktop') !== null ? setMapImg(location.address_id, 'desktop') : location.link_desktop_map"
						alt="map"
						class="img-fluid d-none d-md-block">
					<img
						:src="setMapImg(location.address_id, 'mobile') !== null ? setMapImg(location.address_id, 'mobile') : location.link_mobile_map"
						alt="map"
						class="img-fluid d-block d-md-none">
				</a>
				<a class="directions-link" :href="location.google_maps_directions" target="_blank" rel="noopener"><i
					class="fas fa-directions"></i> Obter direcções</a>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name       : 'AddressV2',
	components : {},
	props      : {
		config : {
			type     : Array,
			required : true,
		},
		assets : {
			type     : Array,
			required : true,
		}
	},
	data() {
		return {};
	},
	mounted() {
	},
	methods : {
		triggerGTM() {
			this.$gtm.trackEvent({
				event          : 'phoneClicked', // Event type [default = 'interaction'] (Optional)
				category       : 'phoneClicked',
				action         : 'phoneClicked',
				label          : 'phoneClicked',
				eventID        : this.$store.state.alid,
				value          : 0,
				noninteraction : false // Optional
			});
			window.alp.trackEvent({ 'event' : 'phoneClicked' });
		},
		dividePhone(str) {
			return str.match(/.{1,3}/g).join(' ');
		},
		setMapImg(id, size) {
			let item = this.assets.filter(e => {
				return e.indexOf(`${id}_map_${size}`) > -1;
			});

			if (item.length > 0) {
				return item[0].replace(
					'https://storage.GRA.cloud.ovh.net/v1/AUTH_7c05e4ce513a488ab8dae8c4e6ca866d/autoleadsdev01/ad-engine',
					'https://showroom.autoleads.pt/ad-engine'
				);
			}

			return null;
		}
	}
};
</script>
