<template>
	<div class="row gallery" id="gallery2">
		<div v-for="(item, i) in images" :key="i" class="img-container p-1"
			 :class="{'col-12 col-lg-6 col-md-6' : grid === 2, 'col-md-6 col-lg-4 col-12' : grid === 3, 'col-lg-3 col-md-6 col-12' : grid === 4}"
			 @click="index = i">
			<a :href="setUrlQuery(item.link)" :target="setBlank(item.link)" rel="noopener">
				<img class="img-fluid" v-lazy="setImg(item.img)" @click="changeVersion(item.version_id)" alt="">
			</a>
		</div>
	</div>
</template>

<script>
export default {
	name       : 'Gallery2',
	components : {},
	props      : {
		galleryImages : {
			type     : Array,
			required : true,
		},
		size          : {
			type     : Number,
			required : true,
		}
	},
	data() {
		return {
			grid   : this.size,
			images : this.galleryImages,
			index  : null
		};
	},
	methods : {
		setUrlQuery(url) {
			let routeQuery = this.$route.query;
			let query      = {};

			for (let [k, v] of Object.entries(routeQuery)) {
				if (k !== 'debug' && k !== 'preview' && k !== 'teste') {
					query[k] = v;
				}
			}
			if (Object.entries(query).length > 0) {
				let serializeObj = Object.entries(query).map(([key, val]) => `${key}=${val}`).join('&');
				if (url.indexOf('#') > -1 && url.indexOf('http') < 0) {
					return `${url}`;
				}

				return `${url}?${serializeObj}`
			}
			return url;
		},
		setImg(img) {
			return `${img}?rand=${Math.round(new Date().getTime() / 1000)}`;
		},
		setBlank(link) {
			if (link.indexOf('#') > -1 && link.indexOf('http') < 0) {
				return '_self';
			}
			return '_blank';
		},
		changeVersion(val) {
			if (val !== undefined) {
				this.$eventBus.$emit('change-version', val);
			}
		}
	},
};
</script>
